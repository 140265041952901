/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-11 16:50:34
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-11 17:51:32
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listPaymentDays = params => axios({
    url: '/api/order/order/orderInfo/listPaymentDays',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listPaymentDaysLog = params => axios({
  url: '/api/order/order/orderInfo/listPaymentDaysLog',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const allPaymentDaysLog = params => axios({
  url: '/api/order/order/orderInfo/allPaymentDaysLog',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const setPaymentDays = params => axios({
  url: '/api/order/order/orderInfo/setPaymentDays',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
