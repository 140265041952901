<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="账期时间">
              <MonthPicker :startTime.sync="searchData.paymentTime" />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="状态">
              <DictSelect
                field="flagPayment"
                :value.sync="searchData.flagPayment"
                style="width: 100%"
                placeholder="状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" v-if="isPatmentDays" @click="toHandler('set')">生成账期</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
        <span slot="paymentTime" slot-scope="text, row">
          <a @click="checkPaymentTime(row)">{{ row.paymentTime }}</a>
        </span>
        <span slot="flagPayment" slot-scope="text, row">
          <a-switch v-model="row.flagPayment" @change="onChangeStatus(row)" />
        </span>
      </a-table>
    </div>
    <a-modal
      title="设置账期"
      :maskClosable="false"
      :visible="visibleTable"
      @ok="handleOk"
      @cancel="handleCancel"
      width="45%"
    >
      <a-form-model ref="searchForm" :model="searchDataTime" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
        <a-form-model-item label="设置账期">
          <MonthPickerTwo
            :startTime.sync="searchDataTime.startTime"
            :endTime.sync="searchDataTime.endTime"
          ></MonthPickerTwo>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title="查看账期日志" :maskClosable="false" v-model="visible" width="50%" :footer="null">
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsCheck"
        :data-source="tableDataCheck"
        bordered
        :pagination="false"
      >
        <span slot="type" slot-scope="text, row">
          <span v-if="row.type == 1">开账</span>
          <span v-if="row.type == 2">关账</span>
        </span>
        <!-- <span slot="flagPayment" slot-scope="text, row">
          <a-switch v-model="row.flagPayment" @change="onChangeStatus(row)" />
        </span> -->
      </a-table>
    </a-modal>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import { listPaymentDays, setPaymentDays } from './api/DealerDepartmentApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'paymentDays',
  components: {
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      searchDataTime: {},
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isPatmentDays: checkPermission('order:set_payment_days'),
      expandedKeys: [0],
      StationId: 0,
      visibleTable: false,
      visible: false,
      tableDataCheck:[],
      columnsCheck: [
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '日志',
          dataIndex: 'remark',
          key: 'remark',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '日志类型',
          dataIndex: 'type',
          key: 'type',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
        },
      ],
      currentTime:''
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 查看账期日志
    checkPaymentTime(row) {
      console.log(row)
      this.visible = true
      let params = {
        setPaymentId: row.id,
      }
      this.axios.get('/api/order/order/orderInfo/allPaymentDaysLog', { params: params }).then((res) => {
       console.log("res",res.body)
       if(res.body){
        this.tableDataCheck=res.body
       }

       console.log("res",this.tableDataCheck)
      })
    },

    handleOk() {
      if (this.searchDataTime.startTime == null || this.searchDataTime.endTime == null) {
        return this.$message.warning('账期不能为空！')
      }
      this.axios.post('/api/order/order/orderInfo/setPaymentDays', this.searchDataTime).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visibleTable = false
          this.searchDataTime = {}
          this.getData()
        }
      })
    },
    handleCancel() {
      this.visibleTable = false
      this.$refs.searchForm.resetFields()
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listPaymentDays({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            this.selectedRowKeys = []
            this.selectedRows = []
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中筛选
    handleSelect(value) {
      let id = Number(value.toString())
      this.StationId = id
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    // 修改状态
    onChangeStatus(row) {
      this.axios.get('/api/order/order/orderInfo/flagPayment/' + row.id).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getData()
        }
      }).catch(err => {
        this.getData()
      })
    },
    toHandler(name) {
      const _this = this
      switch (name) {
        case 'set':
          _this.visibleTable = true
          // _this.$confirm({
          //   title: '设置账期',
          //   content: '确认要设置当月账期吗?',
          //   okText: '确认',
          //   cancelText: '取消',
          //   async onOk() {
          //     const res = await setPaymentDays()
          //     if (res.code === 200) {
          //       _this.$notification.success({ message: res.message })
          //       _this.getData()
          //     } else {
          //       _this.$notification.error({ message: res.message })
          //     }
          //   },
          //   onCancel() {},
          // })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.tree-outer {
  height: 500px;
  overflow-y: auto;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
