/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-11 16:50:34
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-11 17:56:35
 */
export const columns = [
{
    title: '账期',
    dataIndex: 'paymentTime',
    key: 'paymentTime',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'paymentTime'}
},
{
    title: '是否开帐',
    dataIndex: 'flagPayment',
    key: 'flagPayment',
    width:200,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagPayment'}
},
{
  title: '操作人',
  dataIndex: 'createUser',
  key: 'createUser',
  /*width:150,*/
  align:'center',
  ellipsis:true
},
{
  title: '操作时间',
  dataIndex: 'createTime',
  key: 'createTime',
  /*width:150,*/
  align:'center',
  ellipsis:true
},

]
